<template>
  <section
    v-if="loading || reviews?.length"
    ref="observeEntity"
    class="reviews-carousel"
  >
    <ServerRender
      is="Heading"
      level="2"
    >
      {{ $t("pages.main.reviews") }}
      <span v-if="count">{{ count }}</span>&nbsp;{{ $t("atoms.goods-count.declination.reviews.plural") }}
    </ServerRender>
    <MCarousel
      :break-points="breakPoints"
      :total-loaded-items="reviews.length"
      @change:slide="loadHiddenReviews"
      @scroll="loadReviewsOnScroll"
    >
      <MInfoBanner :num="4.75" />
      <MReviewHomepage
        v-for="(review, i) in reviews"
        :key="review.id"
        :review="review"
        :color="i % 3 === 2 ? 'red' : i % 3 === 1 ? 'blue' : 'yellow'"
        :loading="loading"
      />
    </MCarousel>
  </section>
</template>

<script setup lang="ts">
import ServerRender from '@/components/helpers/ServerRender'

import { computed, defineComponent, ref } from 'vue'
import { useAPI } from '#imports'

import type { PropType, Ref } from 'vue'
import type { Breakpoints } from '@/composables/device'
import type { Review } from '@/modules/nuxt-api/models/Review'

import MCarousel from '@/components/molecules/Carousel/MCarousel.vue'
import MInfoBanner from '@/components/molecules/InfoBanner/MInfoBanner.vue'
import MReviewHomepage from '@/components/molecules/ReviewHomepage/MReviewHomepage.vue'

defineComponent({ name: 'OReviewsCarousel' })

defineProps({
  breakPoints: {
    type: Object as PropType<Record<Breakpoints, number>>,
    default: () => ({
      mobile: 6,
      laptop: 3,
      desktop: 4
    })
  }
})

// API
const api = useAPI()
const { getBestReviews } = api.reviews()

const lazyLoadedReviews: Ref<Review[]> = ref([])
// decided to use a constant instead of data from api
const count = 52000
const reviewsNumInSlide = 6

const cursor = ref((reviewsNumInSlide * 3 - 1).toString())
const reviewsPaginator = computed(() => ({ size: reviewsNumInSlide, cursor: cursor.value }))
const loading = ref(process.client)
const isHiddenReviewsLoading = ref(false)

const loadReviewsOnScroll = (e: Event): void => {
  const target = e.target as HTMLElement

  if (target?.scrollWidth - target?.scrollLeft <= window.innerWidth * 2 && !isHiddenReviewsLoading.value) {
    loadHiddenReviews()
  }
}

const loadHiddenReviews = async (slide?: number): Promise<void> => {
  isHiddenReviewsLoading.value = true
  const totalSlideNum = Math.ceil(reviews.value.length / reviewsNumInSlide)

  if (!slide || totalSlideNum - slide <= 2) {
    await getBestReviews(reviewsPaginator.value)
      .then(([resReviews, resPaginator]) => {
        lazyLoadedReviews.value.push(...(resReviews ?? []))
        // @ts-expect-error
        cursor.value = resPaginator?.nextCursor
      })
      .catch(() => undefined)
  }

  isHiddenReviewsLoading.value = false
}

const reviewsList = ref()
const reviews = computed(() => [...(reviewsList.value ?? []), ...lazyLoadedReviews.value])

await useHydrationData('best-reviews', () => getBestReviews({ size: 17, cursor: '0' }), {
  then: ({ data }) => {
    reviewsList.value = data.value?.[0]
    // @ts-expect-error
    cursor.value = data.value?.[1]?.nextCursor
    loading.value = false
  },
  catch: () => undefined,
  lazy: true
})
</script>

<style lang="postcss">
.reviews-carousel {
  margin-top: var(--spacer-7xl);

  & > .heading {
    display: inline-block;
    margin-bottom: var(--spacer-2xl);

    & > span {
      color: var(--primary);
    }

    @mixin text-5xl;
  }

  @media (--screen-xs) {
    margin-top: var(--spacer-4xl);

    & > .heading {
      margin-bottom: var(--spacer-base);

      @mixin text-2xl;
    }

    & > .carousel {
      --carousel-item-width: 11.75rem;
    }
  }
}
</style>
